import { createActionGroup, emptyProps, props } from '@ngrx/store';

const resetActions = createActionGroup({
  source: '[Reset page]',
  events: {
    'reset email success': emptyProps(),
    'reset password': props<{ email: string }>(),
    'set reset password': props<{ newPassword: string; token: string }>(),
  },
});

export default resetActions;
